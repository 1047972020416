// ** Redux Imports
import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' 
// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import users from './user/index'

const rootReducer = combineReducers({
  firebase: firebaseReducer,
 firestore: firestoreReducer,
 users,
  auth,
  navbar,
  layout
})

export default rootReducer
